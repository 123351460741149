import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px !important;

  .input-container {
    position: relative;
    align-items: center;
    .min-button {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      right: 0;
      margin-bottom: 0.0938rem;
      height: 2.425rem;
      width: 40px;
      background: #8850bf;
      border-radius: 0px 4px 4px 0px;
      &:hover {
        background: #57069e;
      }
      &:focus {
        background: #57069e;
      }
    }
    .min-button-loading {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      right: 0;
      height: 37px;
      width: 40px;
      background: #8850bf;
      border-radius: 0px 4px 4px 0px;
      cursor: not-allowed;
      &:hover {
        background: #57069e;
      }
      &:focus {
        background: #57069e;
      }
    }

    .form-control {
      padding: 0.375rem 0.75rem;
    }

    .form-control.is-invalid {
      background-image: none;
      border: 2px solid var(--ds-border-neutral, #dfe1e6);
      border-bottom: 2px solid #cf1919 !important ;
      &:focus {
        box-shadow: none;
      }
      padding: -0.375rem -0.75rem !important;
    }
  }
`;
