import Button from '@atlaskit/button';
import { yupResolver } from '@hookform/resolvers/yup';
import { GridRowParams } from '@material-ui/data-grid';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FaGlobeAmericas } from 'react-icons/fa';
import { toast } from 'react-toastify';
import DefaultLoader from '~/components/DefaultLoader';
import FormDefault from '~/components/FormDefault';
import {
  InputNumber,
  InputSelect,
  InputText,
  InputUf,
} from '~/components/NovosInputs';
import Search from '~/components/Search';
import api from '~/services/api';

import { Container } from './styles';
import { IPais, Options } from './types';
import { schema } from './Validations';

const Cidade: React.FC = () => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });
  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [manterDados, setManterDados] = useState(false);
  const [iniSigla, setIniSigla] = useState(false);
  const [iniPais, setIniPais] = useState(false);
  const [paises, setPaises] = useState<Options[]>([]);

  const divRef = useRef<HTMLDivElement | null>(null);

  const formBlank = {
    cod_municipio_ibge: '',
    des_cidade: '',
    des_sigla: { value: undefined, label: 'Selecione a UF' },
    cod_pais: { value: undefined, label: 'Selecione o País' },
    num_dipam: 0,
  };

  useEffect(() => {
    (async () => {
      if (errors.des_sigla) {
        setIniSigla(true);
      }
    })();
  }, [errors.des_sigla]);

  useEffect(() => {
    (async () => {
      if (errors.cod_pais) {
        setIniPais(true);
      }
    })();
  }, [errors.cod_pais]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/pais');
      const options = data.data.map((pais: IPais) => {
        return {
          label: `${pais.des_pais}`,
          value: pais.cod_pais,
        };
      });

      options.sort(function (a: any, b: any) {
        if (a.label < b.label) {
          return -1;
        }
        return true;
      });
      setPaises(options);
    })();
  }, []);

  const onNew = () => {
    setIsUpdate(false);
    setShowSearch(false);
    resetFormData();
  };

  const resetFormData = () => {
    setIsUpdate(false);
    reset(formBlank);

    if (divRef.current) {
      const inputCodIbge: HTMLInputElement | null =
        divRef.current.querySelector('[name="cod_municipio_ibge"]');
      if (inputCodIbge) inputCodIbge.value = '';

      const inputUf: HTMLInputElement | null =
        divRef.current.querySelector('[name="des_sigla"]');

      if (inputUf) {
        inputUf.value = '';
        inputUf.placeholder = 'Selecione a UF';
      }

      const inputPais: HTMLInputElement | null =
        divRef.current.querySelector('[name="cod_pais"]');

      if (inputPais) {
        inputPais.value = '';
        inputPais.placeholder = 'Selecione o País';
      }

      const inputDipam: HTMLInputElement | null =
        divRef.current.querySelector('[name="num_dipam"]');
      if (inputDipam) {
        inputDipam.value = '';
        inputDipam.placeholder = '0';
      }
    }
  };

  const onSubmit = handleSubmit(async (data1: any) => {
    if (data1.cod_municipio_ibge <= 0) {
      setLoader(false);
      return toast.warning(
        'Verifique se todos os campos foram preenchidos corretamente.',
      );
    }

    if (isUpdate) {
      const res = await api.put(`/cidade/${data1.cod_cidade}`, {
        cod_municipio_ibge: data1.cod_municipio_ibge,
        des_cidade: data1.des_cidade.trim(),
        cod_pais: data1.cod_pais.value,
        des_sigla: data1.des_sigla.label,
        num_dipam: Number(data1.num_dipam),
      });
      const { success, message } = res.data;
      if (success) {
        resetFormData();
        setLoader(false);
        setShowSearch(true);
        return toast.success(message);
      }
    }
    const res = await api.post('/cidade', {
      cod_municipio_ibge: data1.cod_municipio_ibge,
      des_cidade: data1.des_cidade.trim(),
      cod_pais: data1.cod_pais.value,
      des_sigla: data1.des_sigla.label,
      num_dipam: Number(data1.num_dipam),
    });

    const { success, message } = res.data;
    if (!success) {
      return setLoader(false);
    }
    resetFormData();
    setLoader(false);
    return toast.success(message);
  });

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    setIsUpdate(true);
    const {
      cod_cidade,
      des_cidade,
      des_sigla,
      cod_municipio_ibge,
      cod_pais,
      num_dipam,
    } = row;

    const des_pais: any = paises.filter((pais: any) => {
      return pais.value === cod_pais;
    });
    setValue('cod_cidade', cod_cidade);
    setValue('des_cidade', des_cidade);
    setValue('des_sigla', {
      label: des_sigla,
      value: des_sigla,
    });
    setValue('cod_municipio_ibge', cod_municipio_ibge);
    setValue('num_dipam', num_dipam);
    setValue('cod_pais', {
      label: des_pais[0].label,
      value: des_pais[0].value,
    });
    setLoader(false);
    setShowSearch(false);
  };

  const handleDelete = async () => {
    const values = getValues();
    const { cod_cidade } = values;
    try {
      const { data } = await api.delete(`/cidade/${cod_cidade}`);
      if (data.success) {
        toast.success('Registro excluido com sucesso');
        setShowSearch(true);
        setIsUpdate(false);
        resetFormData();
      }
    } catch (error: any) {
      toast.warn('Erro ao excluir registro');
    }
  };

  const handleSwitch = useCallback(() => {
    setManterDados(!manterDados);
  }, [manterDados]);

  if (loader) {
    return <DefaultLoader />;
  }

  return (
    <Container ref={divRef}>
      {showSearch && (
        <Search
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          codTela={54}
        />
      )}
      {!showSearch && (
        <FormDefault
          codTela={54}
          title="Cadastro de Cidade"
          codigoRegistro={[
            {
              value: getValues('cod_cidade'),
              des_campo: 'Código',
            },
          ]}
          onSave={onSubmit}
          onCancel={() => {
            resetFormData();
            setIsUpdate(false);
            setShowSearch(true);
          }}
          onNew={() => onNew()}
          onDelete={handleDelete}
          onClearFields={resetFormData}
          onSwitch={handleSwitch}
          showSwitch={false}
          manterDados={manterDados}
          isUpdate={isUpdate}
          onReturnSearch={() => {
            setShowSearch(true);
            resetFormData();
          }}
        >
          <Row>
            <Col sm={12} md={2} className="mt-4">
              <div className="input-container">
                <InputNumber
                  label="Código IBGE"
                  name="cod_municipio_ibge"
                  placeholder="0"
                  maxLength={10}
                  max={999}
                  min={1}
                  register={register}
                  isError={!!errors.cod_municipio_ibge}
                  control={control}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setValue(
                      'cod_municipio_ibge',
                      Math.abs(Number(e.target.value)),
                    );
                  }}
                  style={{
                    paddingRight: '20px',
                  }}
                />
                <Button
                  onClick={() => {
                    window.open(
                      'https://www.ibge.gov.br/explica/codigos-dos-municipios.php',
                    );
                  }}
                  type="button"
                  title="Ir para site do IBGE"
                  className="min-button"
                >
                  <FaGlobeAmericas color="#fff" />
                </Button>
              </div>
            </Col>
            <Col sm={2} md={8} className="mt-4">
              <InputText
                label="Descrição"
                name="des_cidade"
                placeholder="Informe a Descrição"
                maxLength={50}
                caseInput="upper"
                register={register}
                control={control}
                isError={!!errors.des_cidade}
              />
            </Col>

            <Col sm={6} md={2} className="mt-4">
              <InputUf
                maxLength={2}
                setValue={setValue}
                placeholder={
                  getValues('des_sigla')?.label
                    ? getValues('des_sigla')?.label
                    : 'Selecione a UF'
                }
                name="des_sigla"
                register={register}
                isError={iniSigla && !!errors.des_sigla}
                control={control}
                changeSelected={(selected) => {
                  setIniSigla(false);
                  setValue('des_sigla', selected);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={9} className="mt-4">
              <InputSelect
                label="País"
                placeholder={
                  getValues('cod_pais')?.label
                    ? getValues('cod_pais')?.label
                    : 'Selecione o País'
                }
                name="cod_pais"
                register={register}
                isError={iniPais && !!errors.cod_pais}
                control={control}
                options={paises}
                changeSelected={(selected) => {
                  setIniPais(false);
                  setValue('cod_pais', selected);
                }}
              />
            </Col>
            <Col sm={12} md={3} className="mt-4">
              <InputNumber
                label="Núm. DIPAM"
                name="num_dipam"
                placeholder="0"
                maxLength={10}
                max={999}
                min={0}
                register={register}
                isError={!!errors.num_dipam}
                control={control}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setValue('num_dipam', e.target.value);
                }}
              />
            </Col>
          </Row>
        </FormDefault>
      )}
    </Container>
  );
};

export default Cidade;
