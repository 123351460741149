import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .super-app-theme--header {
    background-color: #8850bf !important;
    color: #fff;
    padding: 0 !important;
    margin: 0 !important;
  }
  .super-app-theme--cell {
    padding: 0 0 0 7px !important;
    margin: 0 !important;
  }

  .actions-cell {
    justify-content: center !important;
    .MuiDataGrid-columnHeaderTitle {
      padding-left: 0px !important;
    }
    button {
      background: transparent !important;
      & hover {
        background: none;
      }
    }
  }
`;
