import Button from '@atlaskit/button';
import { yupResolver } from '@hookform/resolvers/yup';
import { DataGrid, GridColumns, GridRowParams } from '@material-ui/data-grid';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { IoMdRemoveCircle } from 'react-icons/io';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { ThemeContext } from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FormDefault from '~/components/FormDefault';
import InputSelect from '~/components/Inputs/InputSelect';
import InputText from '~/components/Inputs/InputText';
import Loja from '~/components/Loja';
import { BuscaProduto } from '~/components/NovosInputs';
import Search from '~/components/Search';
import Separator from '~/components/Separator';
import { LojaContext } from '~/context/loja';
import api from '~/services/api';

import { ButtonRow } from '../Cliente/styles';
import { Container } from './styles';
import { Check, ProdutosProps, schemaCheckList } from './types';

const tiposChecklist = [
  {
    value: 0,
    label: 'Outros',
  },
  {
    value: 1,
    label: 'Carga',
  },
];
const FormDataDefault = {
  cod_lista: -1,
  descricao: '',
  tipo_lista: -1,
  lojas: [],
};

const MySwal = withReactContent(Swal);

const CheckList: React.FC = () => {
  const { codLoja } = useContext(LojaContext);
  const [init, setInit] = useState<boolean>(false);
  const [invalidLoja, setInvalidLoja] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [loaderTable, setLoaderTable] = useState<boolean>(false);
  const [inputValidate, setInputValidate] = useState<string[]>([]);
  const [produtos, setProdutos] = useState<ProdutosProps[]>([]);
  const [resetCampo, setResetCampo] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [formData, setFormData] = useState<Check>(FormDataDefault);
  const [tipoLista, setTipoLista] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { colors } = useContext(ThemeContext);

  const {
    register,
    control,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaCheckList),
    reValidateMode: 'onSubmit',
  });

  const resetFormData = () => {
    setInit(false);
    setResetCampo(true);
    setInvalidLoja(false);
    setTipoLista(-1);
    setUpdate(false);
    setFormData(FormDataDefault);
    setValue('produto', []);
    setProdutos([]);
    reset();
    setCurrentPage(0);
  };

  useEffect(() => {
    if (Array.isArray(formData.lojas) && formData.lojas.length <= 0) {
      setFormData((prev) => ({
        ...prev,
        lojas: [codLoja],
      }));
    }
  }, [formData.lojas, codLoja, reset]);

  const handleAddProduto = useCallback(
    (itens: any[]) => {
      setLoaderTable(true);
      if (itens.length === 0) {
        setLoaderTable(false);
        return toast.warning('Produtos não encontrados na loja selecionada');
      }

      const formattedItems = itens.map((item) => ({
        key: parseInt(item.cod_produto.replace(/^0+/, ''), 10),
        cod_produto: item.cod_produto,
        des_produto: item.des_produto,
      }));

      const uniqueItems = formattedItems.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.key === item.key),
      );

      const existingProducts = new Set(
        produtos.map((p) => parseInt(p.id.replace(/^0+/, ''), 10)),
      );
      const updatedProducts: ProdutosProps[] = [...produtos];

      uniqueItems.forEach((item) => {
        const { key, cod_produto, des_produto } = item;

        if (cod_produto) {
          if (existingProducts.has(key)) {
            toast.warning('Item já adicionado no checklist');
          } else {
            existingProducts.add(key);
            updatedProducts.push({ id: cod_produto, descricao: des_produto });
          }
        }
      });

      updatedProducts.sort((a, b) => a.descricao.localeCompare(b.descricao));

      setValue('produto', updatedProducts);
      setProdutos(updatedProducts);
      setLoaderTable(false);
    },
    [produtos],
  );

  const handleRemoveProduto = useCallback(
    (id: any) => {
      MySwal.fire({
        title: `Check-List`,
        text: 'Deseja remover este item do check-list?',

        showCancelButton: true,
        confirmButtonColor: '#07289e',
        cancelButtonColor: '#ff7b7b',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoaderTable(true);
          const prods = produtos.filter((p: { id: any }) => p.id !== id);
          setValue('produto', prods);
          setProdutos(prods);
          setLoaderTable(false);
        }
        setLoaderTable(false);
      });
    },
    [produtos],
  );

  const handleRemoveAllProdutos = useCallback(() => {
    MySwal.fire({
      title: `Check-List`,
      text: 'Deseja remover todos os itens do check-list?',

      showCancelButton: true,
      confirmButtonColor: '#07289e',
      cancelButtonColor: '#ff7b7b',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setValue('produto', []);
        setProdutos([]);
      }
    });
  }, []);

  const tabColumns: GridColumns = [
    {
      field: 'id',
      headerName: 'Código',
      width: 150,
      cellClassName: 'super-app-theme--cell',
      headerClassName: 'super-app-theme--header',
      align: 'left',
    },
    {
      field: 'descricao',
      headerName: 'Descrição',
      flex: 1,
      minWidth: 200,
      cellClassName: 'super-app-theme--cell',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: '',
      headerName: 'Ação',
      sortable: false,
      width: 100,
      headerAlign: 'center',
      align: 'center',
      cellClassName: 'super-app-theme--cell actions-cell',
      headerClassName: 'super-app-theme--header actions-cell',
      // // disableClickEventBubbling: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        const onRemove = () => {
          handleRemoveProduto(row.id);
        };
        return (
          <>
            <ButtonRow type="button" onClick={onRemove}>
              <IoMdRemoveCircle size={20} style={{ color: '#e63c3c' }} />
            </ButtonRow>
          </>
        );
      },
    },
  ];

  /**
   * Validações
   */
  const handleValidate = useCallback(
    (val, field) => {
      // Campo do tipo string
      if (typeof val === 'string') {
        // Caso queira exibir a mensagem sem validar se o campo está preenchido
        if (val === 'notvalid') {
          const search = inputValidate.filter((invalid) => invalid === field);
          if (Object.keys(search).length < 1) {
            setInputValidate([...inputValidate, field]);
          }
          // Caso o campo não passe na validação a mensagem é exibida
        } else if (val.length <= 0) {
          const search = inputValidate.filter((invalid) => invalid === field);
          if (Object.keys(search).length < 1) {
            setInputValidate([...inputValidate, field]);
          }
          // Caso o campo passe na validação a menssagem é removida
        } else {
          const search = inputValidate.filter((valid) => valid !== field);
          setInputValidate(search);
        }
        // Campo do tipo numérico
      } else if (val.toString().length <= 0 || Number.isNaN(val)) {
        const search = inputValidate.filter((invalid) => invalid === field);
        if (Object.keys(search).length < 1) {
          setInputValidate([...inputValidate, field]);
        }
      } else {
        const search = inputValidate.filter((valid) => valid !== field);
        setInputValidate(search);
      }
    },
    [inputValidate],
  );

  const handleChangeTipo = (val: any) => {
    setFormData({
      ...formData,
      tipo_lista: val.value,
    });
    setValue('tipo_lista', val);

    const findTipo = tiposChecklist.find((t) => t.value === val.value);
    if (findTipo) {
      setTipoLista(findTipo);
    }

    handleValidate(val.value, 'tipo');
  };

  const onRowClick = async (param: GridRowParams) => {
    setLoader(true);
    const { row } = param;
    const { tipo_lista, tipo_da_lista, des_lista, cod_lista } = row;
    setFormData({
      ...formData,
      cod_lista,
      descricao: des_lista,
      tipo_lista,
    });

    const findTipo = tiposChecklist.find((t) => t.label === tipo_da_lista);
    if (findTipo) {
      setTipoLista(findTipo);
    }

    const { data } = await api.get(`/checklist/produtos/${cod_lista}`);
    if (data.success) {
      const formattedProdutos = data.data.flatMap((p: any) =>
        p.cod_loja === codLoja
          ? [{ ...p, id: p.cod_produto, descricao: p.des_produto }]
          : [],
      );
      setValue('produto', formattedProdutos);
      setProdutos(formattedProdutos);
    }
    setUpdate(true);
    setCurrentPage(0);
    setShowSearch(false);
    setLoader(false);
  };

  const onCancel = () => {
    resetFormData();
    setShowSearch(true);
  };

  const onDelete = async () => {
    const res = await api.delete(`/checklist/${formData?.cod_lista}`);
    const { message } = res.data;
    onCancel();
    return toast.success(message);
  };

  const onNew = () => {
    resetFormData();
    setUpdate(false);
  };

  const onSave = async () => {
    setLoader(true);
    setInit(true);
    try {
      const { produto, descricao, tipo_lista, lojas } = getValues();
      if (
        descricao === '' ||
        (Array.isArray(lojas) && lojas.length === 0) ||
        tipo_lista.value < 0
      ) {
        if (descricao === '') {
          handleValidate('notvalid', 'descricao');
        }
        if (Array.isArray(lojas) && lojas.length === 0) {
          handleValidate('notvalid', 'loja');
          setInvalidLoja(true);
        }
        setLoader(false);
        return;
      }

      if (produto.length <= 0) {
        toast.warning('Ao menos um produto deve ser informado.');
        setLoader(false);
        return;
      }

      const formattedProducts = produto.map((p: any) => ({
        cod_produto: p.id,
        descricao: p.descricao,
      }));

      const dataForm = {
        produtos: formattedProducts,
        descricao: descricao.trim(),
        tipo_lista: tipo_lista.value,
        lojas,
      };

      if (isUpdate) {
        const { data } = await api.put(
          `/checklist/${formData.cod_lista}`,
          dataForm,
        );

        if (data.success) {
          toast.success(data.message);
          resetFormData();
          setShowSearch(true);
        }
        setLoader(false);
      } else {
        const { data } = await api.post('/checklist', dataForm);

        if (data.success) {
          toast.success(data.message);
          resetFormData();
        }
      }
    } finally {
      setLoader(false);
    }
  };

  if (loader) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <ClipLoader color={colors.primary} />
        </div>
      </Container>
    );
  }
  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          codTela={31}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={31}
          title="Check-List"
          codigoRegistro={[
            {
              des_campo: 'Código',
              value: formData.cod_lista,
            },
          ]}
          onCancel={onCancel}
          onClearFields={resetFormData}
          onDelete={onDelete}
          onNew={onNew}
          onReturnSearch={onCancel}
          onSave={onSave}
          isUpdate={isUpdate}
        >
          <Col sm={12} md={12}>
            <div className="row">
              <div className="col-sm-12 col-md-10">
                <InputText
                  label="Descrição"
                  maxLength={50}
                  placeholder="Informe a Descrição"
                  value={formData?.descricao}
                  iniInicializado={init}
                  setInvalid={formData?.descricao === ''}
                  isRequired
                  onChange={(val) => {
                    handleValidate(val, 'descricao');
                    if (formData) {
                      setFormData({
                        ...formData,
                        descricao: val.trimStart().toUpperCase(),
                      });
                      setValue('descricao', val.trimStart().toUpperCase());
                    }
                  }}
                />
              </div>

              <div
                className={
                  isUpdate ? 'col-sm-12 col-md-2' : 'col-sm-12 col-md-2'
                }
              >
                <InputSelect
                  label="Tipo"
                  name="tipo"
                  options={tiposChecklist}
                  placeholder="Informe o Tipo"
                  value={tipoLista}
                  onChange={(value) => handleChangeTipo(value)}
                  iniInicializado={init}
                  setInvalid={formData.tipo_lista < 0}
                  isRequired
                />
              </div>
              <Col md={12} sm={12}>
                <Separator labelText="Pesquisar Produtos" />
              </Col>
              {/* Buscar produtos */}
              <div className="col-sm-12 col-md-12">
                <Loja
                  isMulti
                  selectedLoja={formData.lojas}
                  onChange={(val) => {
                    setFormData({ ...formData, lojas: val });
                    setValue('lojas', val);
                    if (Array.isArray(val) && val.length > 0) {
                      setInvalidLoja(false);
                    } else if (init) setInvalidLoja(true);
                  }}
                  IsInvalid={invalidLoja}
                />
              </div>
              <div className="row">
                <div style={{ width: '100%' }}>
                  <BuscaProduto
                    label="Buscar por"
                    placeholder="Selecione..."
                    name="busca_por"
                    register={register}
                    isError={!!errors.busca_por}
                    control={control}
                    buscaNasLojas={formData.lojas || [codLoja]}
                    buscaItensInativos={false}
                    clearCampoBusca={resetCampo}
                    customOptions={{
                      hideBuscarPor: true,
                      buscarPor: [
                        'Produto',
                        'Departamento',
                        'Fornecedor',
                        'NCM',
                        'Marca',
                      ],
                      buscaPadrao: 'Produto',
                      showSelecionarItensContendo: true,
                    }}
                    getProduct={(selected: any) => {
                      handleAddProduto(selected);
                      setResetCampo(false);
                    }}
                    setValue={setValue}
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
              {/* tabela de produtos similares */}
              <div
                className="col-sm-12 col-md-12 dataTable"
                style={{ height: 400, width: '100%', marginTop: '30px' }}
              >
                {loaderTable === true && (
                  <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                    <ClipLoader color={colors.primary} />
                  </div>
                )}
                {loaderTable === false && (
                  <DataGrid
                    page={currentPage}
                    onPageChange={(page) => {
                      setCurrentPage(page);
                    }}
                    rows={produtos}
                    columns={tabColumns}
                    pageSize={5}
                    disableColumnFilter
                    disableColumnSelector
                    disableColumnMenu
                    disableSelectionOnClick
                    localeText={{
                      noRowsLabel:
                        'Nenhum registro encontrado com o critério informado',
                    }}
                  />
                )}
              </div>
              <div className="d-flex justify-content-end my-3 px-4">
                <Button
                  type="button"
                  onClick={handleRemoveAllProdutos}
                  isDisabled={produtos.length === 0}
                >
                  REMOVER TODOS ITENS
                </Button>
              </div>
            </div>
          </Col>
        </FormDefault>
      )}
    </Container>
  );
};

export default CheckList;
