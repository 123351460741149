import * as yup from 'yup';

export type Check = {
  cod_lista: number;
  descricao: string;
  tipo_lista: number;
  lojas: number | number[];
};

export interface ProdutosProps {
  id: string;
  descricao: string;
}

export const schemaCheckList = yup.object({
  busca_por: yup.object().shape({
    label: yup.string().required(),
    value: yup.number().required(),
  }),
});
