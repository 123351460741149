import * as yup from 'yup';

export const schema = yup.object({
  cod_municipio_ibge: yup.number().required(),
  des_cidade: yup.string().required(),
  des_sigla: yup.object().shape({
    label: yup.string().required(),
    value: yup.string().required(),
  }),
  cod_pais: yup.object().shape({
    label: yup.string().required(),
    value: yup.number().required(),
  }),
});
